body {
  background-color: rgba(0, 0, 0, 0.639) !important;
}

.miniProfileContainer {
    display: flex;
    
    align-items: center;
    padding: 1vmax  2vmax ;
    justify-content: space-between;
    border-left: none;
    box-sizing: border-box;
    border-left: 5px solid #DAF2FF;

}

.myProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}

.myProfile > h1 {
    font: 1000 25px "Roboto";
    letter-spacing: 0.1vmax;
    word-spacing: 0.3vmax;
    color: rgba(0, 0, 0, 0.806);
    text-transform: uppercase;
    letter-spacing: 0.3vmax;
    padding-top: 0.5vmax;

}

.myProfile > h4 {
    font: 1000 20px cursive;
    letter-spacing: 0.1vmax;
    word-spacing: 0.3vmax;
    color: rgba(0, 0, 0, 0.806);
}

.myProfileBtn {
    padding-right: 23vmax;

}
.secondProfileContainer {
    display: flex;
    align-items:center;
    justify-content: space-between;
}
.secondProfileContainer > div {
display: flex;
box-sizing: border-box;
}

.secondProfileContainer > div:first-child {
    width: 60%;

}
.secondProfileContainer > div:last-child {
    width: 20%;
}

.myProfileBtn > p {
    background-color: darkblue;
    color: white;
    padding: 1vmax 2vmax;
    border-radius: 7px;
    font: 100 14px "Roboto";
    transition: all 0.5s;
    cursor: pointer;
}

.myProfileBtn > p:hover {
    background-color: white;
    color: darkblue;
    border: 2px solid darkblue;
}

.queryhelp > select {
    padding: 0.5vmax 5vmax;
    font: 200 15px "Poppins";
    color: rgba(0, 0, 0, 0.483);
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(0, 0, 0, 0.171);

}
.queryhelp{
    padding: 1vmax ;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.helpContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vmin;
    background-color: rgba(255, 255, 255, 0);
    padding: 1rem;
    z-index: 1;

  }
  
  .myProfileFormMini {
    background: rgba(255, 255, 255, 0.372);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
  }
  
  .title {
    margin-bottom: 1.5rem;
    color: #2c3e50;
    font-family: "Roboto";
    text-transform: uppercase;
    text-shadow:  0 0 7px rgba(0, 0, 0, 0.5);
    font-size: 22px;
  }

  .label {
    text-align: left;
  }

  .input {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
    resize: none;
  }
  
  
  .buttonBtn {
    width: 100%;
    padding: 0.75rem;
    margin: 1rem 0;
    border: none;
    border-radius: 4px;
    background-color: black;
    color: white;
    font-size: 1rem;
    transition: all 0.5s;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.3vmax;
  background-color: #1a2a4b;

  }

  .buttonBtn:hover {
    color: black;
    border: 2px solid black;
    background-color: rgba(255, 255, 255, 0);
  }
  


  /* Responsive styles */
  @media (max-width: 768px) {
    .myProfileFormMini {
      padding: 1.5rem;
    }
    .secondProfileContainer {
        flex-direction: column;
        overflow: hidden;
    }
    .secondProfileContainer > div {
        width: 100% !important;
        margin: 4vmax 0vmax;
    }
  }
  
  @media (max-width: 480px) {
    .myProfileFormMini {
      padding: 1rem;
    }
  }
  

@media screen and (max-width: 650px) {
    .myProfileBtn {
        padding-right: 2vmax;
    }
}




