.avatarImage {
    width: 70px;
    height: 70px;
    border-radius: 100%;
}

#avatarPreview {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen  and (max-width: 768px) {
    .avatarImage {
        width: 7vmax;
        height: 7vmax;
    }
    
}

.sign-up-container {
    display: flex;
}

.signupInput {
    margin: 1vmax;
}




/*----new css ----*/
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.containerSignupUser 
{
  

  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  box-sizing: border-box;
  min-height: 120vmin;
  width: 100vw ;
  max-width: 100%;
  overflow: hidden !important;


}
.containerSignupUser >  section 
{
  width: 120vw;
  min-height: 140vmin;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  overflow: hidden !important;


}
.containerSignupUser > section::before 
{
  content: '';
  min-height: 120vmin;
  overflow: hidden !important;

  width: 100%;
  background: linear-gradient(#000,#0f0,#000);
  background: linear-gradient(#000,#85D1FF,#000);
  animation: animate 5s linear infinite;
}

@keyframes animate 
{
  0%
  {
    transform: translateY(-100%);
  }
  100%
  {
    transform: translateY(0%);
  }
}
.containerSignupUser section span 
{
  position: relative;
  display: block;
  width: calc(6.25vw - 4px);
  height: calc(6.25vw - 4px);
  z-index: 2;
  transition: 1.5s;
  background: #2C7EAD;
  box-sizing: border-box;
  overflow: hidden !important;

}
section span:hover 
{
  background: #0f0;
  background: yellowgreen;
  background:#85D1FF ;
  transition: 0s;
}

.containerSignupUser section .signup
{
  width: 600px;
  background: #222;  
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
    overflow: hidden !important;
    border-radius: 4px;
  box-shadow: 0 15px 35px rgba(0,0,0,9);
  background-color: white;
  box-sizing: border-box;
  min-height: 100vmin;
 position:absolute;

}
section .signup .signup-content 
{
  position: relative;
  width: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}
section .signup .signup-content h2 
{
  font-size: 1.5em;
  color: #0f0;
  text-transform: uppercase;
  color: yellowgreen;
  text-align: center;
  color: #2C7EAD;

}
section .signup .signup-content .signupForm 
{
  width: 110%;
  display: flex;
  flex-direction: column;
}
section .signup .signup-content .signupForm .signupInput
{
  position: relative;
  width: 100%;
}
section .signup .signup-content .signupForm .signupInput input 
{
  position: relative;
  width: 100%;
  background: white;
  border: none;
  outline: none;
  border-bottom: 3px solid black;

  padding: 25px 10px 7.5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1em;
  color: #aaa;

}
section .signup .signup-content .signupForm .signupInput i 
{
  position: absolute;
  left: 0;
  padding: 15px 10px;
  font-style: normal;
  color: #aaa;
  transition: 0.5s;
  pointer-events: none;
}
.signup .signup-content .signupForm .signupInput input:focus ~ i,
.signup .signup-content .signupForm .signupInput input:valid ~ i
{
  transform: translateY(-7.5px);
  font-size: 0.8em;
  color: #aaa;
}
.signup .signup-content .signupForm .links 
{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.signup .signup-content .signupForm .links a:nth-child(2)
{
  color: #0f0;
  font-weight: 600;
  color: #85D1FF;
}
.signup .signup-content .signupForm .signupInput input[type="submit"]
{
  padding: 10px;
  background: #0f0;
  color: #000;
  font-weight: 600;
  font-size: 1.35em;
  letter-spacing: 0.05em;
  cursor: pointer;
  background: #2C7EAD;
  color: white;
  border-bottom: none;



}
input[type="submit"]:active
{
  opacity: 0.6;
}
@media (max-width: 900px)
{
  section span 
  {
    width: calc(14vw - 4px);
    height: calc(14vw - 4px);
  }
}
@media (max-width: 600px)
{
 
  section .signup {
    width: 100px;
  }
  .containerSignupUser {
    min-height: 200vmin;
    overflow: hidden !important;
  }
  section .signup .signup-content {
    width: 60%;
  }
  .containerSignupUser section .signup {
    width: 500px;
  }


  .sign-up-container {
    flex-direction: column;
  }

}

@media (max-width: 500px) {
  .containerSignupUser {
    min-height: 260vmin;
  }
  .containerSignupUser section .signup {
    width: 400px;
  }
}
@media (max-width: 400px) {
  .containerSignupUser {
    min-height: 300vmin;
  }
  .containerSignupUser section .signup {
    width: 300px;
  }
}
@media (max-width: 350px) {
  .containerSignupUser {
    min-height: 350vmin;
  }
  section .signup .signup-content {
    width: 40%;
  }
}

.newSignupFields {
  padding: 0vmax 2vmax;
}


.newSignupFields input[type="file"]::file-selector-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}