.category-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 40px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    max-width: 90vmax;
  }

  .lessonboxContainer {
    background-color: #49974E;
    border-radius: 8px;

    margin: 0.5vmax;
    transition: transform 0.5s;


    text-decoration: none;
  }
  .miniProfileContainer {
    display: flex;
    
    align-items: center;
    justify-content: space-between;
    border-left: none;
    box-sizing: border-box;

}

.myProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    padding: 0vmax 1vmax;
    border-left: 10px solid #D9F1FF;
    padding-top: 0.3vmax;

}

.myProfile > h1 {
    font: 1000 25px "Roboto";
    letter-spacing: 0.1vmax;
    word-spacing: 0.3vmax;
    color: rgba(0, 0, 0, 0.806);
    text-transform: uppercase;
    letter-spacing: 0.3vmax;
    text-align: center;

}
  .category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    cursor: pointer;
    border-radius: 8px;
    color: white;
    font-size: 1.2em;
    text-align: center;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(139, 111, 111, 0.2);
    width: 250px;
    height: 250px;
  }

  .lessonboxContainer:nth-child(1) {
    background-color:  #346C94;
  }
  
  .lessonboxContainer:nth-child(2) {
    background-color: #FF5757 ;

  }
    
  .lessonboxContainer:nth-child(3) {
    background-color: #CB699E;

  }
  .lessonboxContainer:nth-child(4) {
    background-color: #5764B8;

  }
  .lessonboxContainer:nth-child(5) {
    background-color: #FF5757;

  }
  .lessonboxContainer:nth-child(6) {
    background-color: #4B5EDF;

  }
  .lessonboxContainer:nth-child(7) {
    background-color: #8E62BB;

  }
  .lessonboxContainer:nth-child(8) {
    background-color: #DB941F;

  }
  .lessonboxContainer:nth-child(9) {
    background-color: #695454;

  }
  .lessonboxContainer:nth-child(10) {
    background-color: #387DFF;

  }

  .lessonboxContainer:nth-child(11) {
    background-color: #BF3942;

  }
  .lessonboxContainer:nth-child(12) {
    background-color: #944578;

  }
  .lessonboxContainer:nth-child(13) {
    background-color: #FF6E39;

  }
  .lessonboxContainer:nth-child(14) {
    background-color: #49974E;

  }
  .lessonboxContainer:nth-child(15) {
    background-color: #4B5EDF;

  }

  .lessonboxContainer:hover {
    transform: scale(1.05);
  }
  
  .category-icon {
    width:170px;
  }
  
  .category-name {
    padding: 10px 0vmax;
    font-family: "Roboto";
  }
  

  
  @media (max-width: 480px) {
 
    .category-item {
      width: 200px;
      height: 200px;
    }
  }
  

  .paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6vmax;
  }
  .pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0vmax auto;
    box-sizing: border-box;
  }

  .page-item{
    background-color: rgb(255, 255, 255);
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    padding: 1vmax 1.5vmax;
    transition: all 0.4s;
    cursor: pointer;
  }
  .page-item:first-child {
    border-radius: 5px 0 0 5px;
  }
  .page-item:last-child {
    border-radius: 0 5px 5px 0;
  }
  .page-link{
    
  text-decoration: none;
  font: 300 1vmax "Roboto";
  color: rgb(80, 80, 80);
  transition: all 0.4s;
  }
  .page-item:hover {
    background-color: rgb(230, 230, 230);
  }
  .page-item:hover .page-link {
    color: rgb(0, 0, 0);
  }
  .pageItemActive {
    background-image: linear-gradient(#0693E3, #0077BB );
  }
  
.pageLinkActive {
  color: white;
}
@media screen and (max-width:1000px) {
  .page-link {
    font: 300 1.7vmax "Roboto";
  }

  .page-item {
    padding: 2vmax 2.5vmax;
  }
}
