.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f5f7fa;
    color: #1a2a4b;
    padding: 1rem;
  }
  
  .not-found-image {
    width: 400px;
    height: auto;
    margin-bottom: 2rem;
  }
  
  .not-found h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #1a2a4b;
  }
  
  .not-found p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #666;
    font: 600 1.2rem "Roboto";
  }
  
  .home-button {
    background-color: #1a2a4b;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s;
    text-decoration: none;
  }
  
  .home-button:hover {
    color: #23395b;
    background-color: white;
    border: 2px solid #23395b;
  }
  
  @media (max-width: 768px) {
    .not-found h1 {
      font-size: 2rem;
    }
  
    .not-found p {
      font-size: 1rem;
    }
  
    .home-button {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 600px) {
  .not-found-image {
    width: 250px;
  }
  }
  