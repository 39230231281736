.updatePasswordContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vmin;
    background-color: white;
    padding: 1rem;
    text-align: center;
  }
  
  .updatePasswordForm {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
  
  }
  
  .title {
    color: #2c3e50;
    font-family: "Roboto";
    text-transform: uppercase;
    font-size: 21px;
  }

  .label {
    text-align: left;
  }

  .input {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
  }
  
  .updatePasswordBtn {
    width: 100%;
    padding: 0.75rem;
    margin: 1rem 0;
    border: none;
    border-radius: 4px;
    background-color:#0077BB;
    color: white;
    font-size: 17px;
    transition: all 0.5s;
    cursor: pointer;
  }

  .updatePasswordBtn:hover {
    color: navy;
    border: 2px solid navy;
    background-color: white;
  }
  

  
  /* Responsive styles */
  @media (max-width: 768px) {
    .updatePasswordForm {
      padding: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .updatePasswordForm {
      padding: 1rem;
    }
  }
  