.highlightContainer {
    display: flex;
    justify-content: space-between;
    margin: 2vmax;
    background-color: #F3FBFF;
    color: black;
    box-sizing: border-box;
    border-radius: 17px;
    box-shadow: 0 0 6px #D9F1FF ;
  }
  
  .highlightContainer > div {
    padding: 0.5vmax 2vmax;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .highlightContainer > div:first-child > p {
    font: 700 21px "Roboto";
    padding: 0.5vmax;
  }
  .highlightContainer > div:last-child > p > svg {
    color: black;
    font-size: 80px;
    padding: 10px;
    border-radius: 100px;
  }

  .highlightContainer > div:last-child > b > svg {
    margin: 0vmax -0.3vmax;
    font-size: 45px;

  }

  .highlightContainer > div:last-child > b:first-child > svg {
    color: white;
    padding: 10px;
    border-radius: 100px;
    background-color: #6EA6C9;
  }
  .highlightContainer > div:last-child > b:nth-child(3) > svg {
    color: white;
    padding: 10px;
    border-radius: 100px;
    background-color: #6EA6C9;
  }
  .highlightContainer > div:last-child > b:nth-child(2) > svg {
    color: #6EA6C9;
    padding: 10px;
    border-radius: 100px;
    background-color: white;
  }
  .highlightContainer > div:last-child > b:nth-child(4) > svg {
    color: #6EA6C9;
    padding: 10px;
    border-radius: 100px;
    background-color: white;
  }
  .highlightContainer > div:first-child > svg {
    font-size: 35px;
  }
  
  
.lesson-container {
  min-height: 100vmin;
  display: flex;
}