.resultMainContainer {
display: flex;
flex-direction: column;
box-sizing: border-box;
width: 70vw;
align-items: center;
justify-content: center;
max-width: 100%;

}


.resultContainer1 {
  display: flex;
  justify-content: space-between;
  background-color: #F3FBFF;
  color: black;
  box-sizing: border-box;
  border-radius: 17px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.21) ;
width: 55vw;
background-color: #C1E3F8;

max-width: 90%;

}

.resultContainer1 > div {
  padding: 0.5vmax 2vmax;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resultContainer1 > div:first-child > div {
  display: flex;
  flex-direction: column;
  padding: 0vmax 0.5vmax ;
}

.resultContainer1 > div:first-child > div > h3 {
  font: 600 19px "Roboto";
  padding: 0.2vmax 0vmax;
}
.resultContainer1 > div:first-child > div > p {
  font: 300 16px "Roboto";
}

.resultContainer1 > div:first-child > div > svg {
  font: 700 21px "Roboto";
}



.resultContainer1 > div:last-child  > p > svg {
  color: black;
  font-size: 80px;
  padding: 10px;
  border-radius: 100px;
}



.resultContainer1 > div:first-child > div > svg {
  font-size: 45px;
}


@media screen and (max-width: 500px) {
  .resultContainer1 > div:first-child > div > h3 , .resultContainer1 > div:first-child > div > p  {
    font-size: 14px;
  }
  .resultContainer1 > div:first-child > div > svg {
    font-size: 30px;
  }
  .resultContainer1 > div:last-child  > p > svg {
    color: black;
    font-size: 60px;
    padding: 10px;
    border-radius: 100px;
  }
  
}