
  /* Container */
  .remarks-container {
    max-width: 800px;
    width: 70vw;
    margin: 20px auto;
    border-radius: 10px;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;

  }

  .remarks-content {
    background-color: #C1E3F8;
    padding: 2vmax;
    border-radius: 17px;
    box-shadow:  0 0 7px rgba(0, 0, 0, 0.199);
  }
  
  /* Header */
  .remarks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .remarks-header h3 {
    font-size: 18px;
  }
  
  .activity-dropdown {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  /* Table */
  .remarks-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .remarks-table th {
    text-align: left;
    font-weight: 800;
    padding-bottom: 10px;
    font-size: 17px;
  }
  
  .remarks-table td {
    padding: 10px 0;
    font-weight: 800;

    vertical-align: middle;
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .dot-blue {
    background-color: #223d6c;
  }
  
  .dot-red {
    background-color: #e95b54;
  }
  
  .dot-orange {
    background-color: #f6b563;
  }
  
  .dot-green {
    background-color: #5fc47b;
  }
  
  .dot-light-orange {
    background-color: #ffbe66;
  }
  
  /* Button */
  .download-button {
    margin-top: 12px;
    width: 90%;
    padding: 12px;
    text-decoration: none;
    border: none;
    border-radius: 10px;
    background-color:#54aef5;
    color: white;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
  }
  .downloadContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .download-button:hover {
    background-color: #468ecf;
  }
  
  /* Footer */
  .remarks-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }
  .remarks-footer > div {
    display: flex;
    align-items: center;
    padding: 0vmax 3vmax ;

    justify-content: space-around;
  }
  .remarks-footer > div > p {
    font: 300 14px "Roboto";
    padding: 0.3vmax;
  }
  
  /* Teacher's Remarks */
  .teacher-remarks {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background-color: #C1E3F8;

    
    padding: 2vmax;
    border-radius: 17px;
    box-shadow:  0 0 7px rgba(0, 0, 0, 0.199);
  }
  
  .teacher-remarks h4 {
    font-size: 19px;
    margin-bottom: 10px;
    
  }
  
  .remarks-textarea {
    width: 100%;
    height: 140px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.155);
    padding: 10px;
    font-size: 14px;
    color: #333;
    outline: none;
    resize: none;
  }
  
  .remarks-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
    justify-content: space-between;
    width: 100%;
    margin-left: 5px;
  }

  .remarks-actions > div {
    display: flex;
    align-items: center;
    justify-content: center;
    
    

  }

  .remarks-actions > div > svg {
   font-size: 28px;
   margin: -6px;
   color: #333;
   fill:#333 ;

  }
  .remarks-actions > div > svg:nth-child(2) {
    fill:white ;

  }
  
  .icon-button {
    margin-right: 10px;
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .icon-button:hover {
    color: #54aef5;
  }
  
  /* Responsiveness */
  @media screen and (max-width: 480px) {
    .remarks-container {
      padding: 15px;
    }
  
    .remarks-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .remarks-header h3 {
      margin-bottom: 10px;
    }
  
    .remarks-table th,
    .remarks-table td {
      font-size: 12px;
    }
  
    .download-button {
      font-size: 12px;
    }
  
    .teacher-remarks h4 {
      font-size: 14px;
    }
  }
  