
.skillsContainer {
  width: 102vw;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background-color: #1a2a4b;

  padding: 0%;
  margin: 0%;
}




.skillsCardContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  height: 100px;
  box-sizing: border-box;
  color: white;
  text-align: center;
  background-color: #2C7EAD;
  flex-direction: column;
}

.skillsCardContainer > img {
  width: 100px;
}


