
  .dashboardSummary > div {
      display: flex;
      background-color: white;
      justify-content: center;
    }
    .dashboardSummary > div > p {
      background-color: #D9F1FF;
      color: rgba(0, 0, 0, 0.697);
      font: 300 1.5rem "Roboto";
      text-align: center;
      padding: 1.6rem;
      width: 100%;
      margin: 0 2rem;
      border-radius: 7px;
    }
    .dashboardSummaryBox2 > a {
      color: rgb(0, 0, 0);
      font: 300 2rem "Roboto";
      text-align: center;
      background-color: rgb(255, 233, 174);
      text-decoration: none;
      padding: 4rem;
      width: 15vmax;
      height: 15vmax;
      margin: 2rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .dashboardSummaryBox2 > a:first-child {
      background-color: rgb(255, 110, 110);
      color: rgb(255, 255, 255);
    }
    
  
  .dashboardSummaryBox2 > a:last-child {
      background-color: rgb(51, 51, 51);
      color: rgb(255, 255, 255);
    }
  
    
  @media screen and (max-width: 780px) {
    .dashboard {
        grid-template-columns: 1fr;
      }
      .dashboardContainer {
        border-left: none;
      }
    
      .dashboardSummary > div > p {
        margin: 0;
      }
    
      .dashboardSummaryBox2 > a {
        padding: 0.5rem;
        margin: 1rem;
        font: 300 0.9rem "Roboto";
      }
  }    
  

  @media screen and (max-width: 380px) {
  
    .dashboardSummaryBox2 > a {
      margin: 7px 0px;

    }
  }