.lessons-outcomes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background-color: #e6f7ff;
    background-image: url('../../images/dashboardd.gif');
    background-repeat: no-repeat;
    background-size: 11vmax;
    background-position:left bottom;
    border-radius: 27px;
    box-shadow:  0 0 6px rgba(0, 0, 0, 0.199);
    align-items: end;
    justify-content: flex-end;
    height: 400px;
    box-sizing: border-box;
  }

  
  .lessons-outcomes-container > div {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;

  }
 
  
  .chart-title {
    font-size: 24px;
    border-left: 7px solid #49974E;
    padding: 0.5vmax 1vmax;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: rgba(0, 0, 0, 0.847);

  }
  
  .legend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin: 5px 10px;
  }
  
  .legend-color {
    width: 10px;
    height: 10px;
    margin-right: 10px;

  }
  
  .legend-label {

    font-size: 14px;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .chart-container {
      width: 100%;
      padding: 10px;
    }
  
    .legend-item {
      margin: 3px 5px;
    }
  }



  .lessons-outcomes-container {
    background-color: #eaf6ff; /* Light blue background similar to the image */
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .chart-title-container {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .chart-title {
    font-size: 24px;
    font-weight: bold;
    color: #333333f3;
  }
  
  .chart-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px; /* Space between chart and legend */
    width: 100%;
  }
  
  .chart-container {
    width: 300px; /* Adjust chart size */
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .chart-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-right: -12vmax;

  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  .legend-label {
    font-size: 16px;
    color: #333;
    font-weight: 600;
  }
  
  @media screen and (max-width: 768px) {
    .chart-layout {
      flex-direction: column; /* Stacks chart and labels on smaller screens */
      gap: 20px;
    }
  }
  

  @media screen and (max-width: 1200px) {
    .chart-layout {
      max-width: 10%;
      border: 1px solid black;

    }
  }
  