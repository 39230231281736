 /*----new css ----*/
 @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
 * {
   
   margin: 0;
   padding: 0;
   box-sizing: border-box;
 
   font-family: 'Quicksand', sans-serif;
 
 }
 .containerLogihUser 
 {
   
 
   display: flex;
   justify-content: center;
   align-items: center;
   background: #000;
   box-sizing: border-box;
   overflow: hidden;
   min-height: 100vh;
   width: 100vw ;
   max-width: 100%;
 
 }
 section 
 {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 2px;
   flex-wrap: wrap;
   overflow: hidden;
 
 }
 section::before 
 {
   content: '';
   position: absolute;
   height: 100%;
   overflow: hidden !important;
 
   width: 100%;
   overflow: hidden;
   background: linear-gradient(#000,#85D1FF,#000);
   animation: animate 5s linear infinite;
 
 }
 .content > div {
   border-radius: 14px ;
 
 }
 .content  > img {
   width: 7vmax;
 }
 .content > div > img {
   width: 7vmax;
 }
 @keyframes animate 
 {
   0%
   {
     transform: translateY(-100%);
   }
   100%
   {
     transform: translateY(0%);
   }
 }
 section span 
 {
   position: relative;
   display: block;
   width: calc(6.25vw - 4px);
   height: calc(6.25vw - 4px);
   z-index: 2;
   transition: 1.5s;
   box-sizing: border-box;
   background: #052640;
   background-color: #2C7EAD;
 
 }
 section span:hover 
 {
   background: #0f0;
   background: yellowgreen;
   background:#85D1FF ;
   transition: 0s;
 }
 
 section .forgotPassword
 {
   position: absolute;
   width: 400px;
   background: #222;  
   z-index: 10;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 40px;
   border-radius: 4px;
   box-shadow: 0 15px 35px rgba(0,0,0,9);
   background-color: #1a2a4b;
   box-sizing: border-box;
   overflow: hidden;
   background: white;
 
 
 }
 section .forgotPassword .content 
 {
   position: relative;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 10px;
   box-sizing: border-box;
   overflow: hidden;
 }
 section .forgotPassword .content h2 
 {
   font-size: 1.2em;
   color: #0f0;
   text-transform: uppercase;
   color: yellowgreen;
   text-align: center;
   color: #85D1FF;
   color: #2C7EAD;
 }
 section .forgotPassword .content .form 
 {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 25px;
 }
 section .forgotPassword .content .form .inputBox
 {
   position: relative;
   margin: 2vmax 0vmax;
   width: 100%;
 }
 section .forgotPassword .content .form .inputBox input 
 {
   position: relative;
   width: 100%;
   background: #333;
   outline: none;
   padding: 25px 10px 7.5px;
   border-radius: 4px;
   font-weight: 500;
   font-size: 1em;
   background: white;
   border: none;
   border-bottom: 3px solid black;
   color: #aaa;
 
 }
 section .forgotPassword .content .form .inputBox i 
 {
   position: absolute;
   left: 0;
   padding: 15px 10px;
   font-style: normal;
   color: #aaa;
   transition: 0.5s;
   pointer-events: none;
 }
 .forgotPassword .content .form .inputBox input:focus ~ i,
 .forgotPassword .content .form .inputBox input:valid ~ i
 {
   transform: translateY(-7.5px);
   font-size: 0.8em;
   color: #aaa;
 
 }
 .forgotPassword .content .form .links 
 {
   position: relative;
   width: 100%;
   display: flex;
   justify-content: space-between;
 }
 .forgotPassword .content .form .links a 
 {
   text-decoration: none;
   color: #aaa;
 
 
 }
 .forgotPassword .content .form .links a:nth-child(2)
 {
   font-weight: 600;
   color: #aaa;
 
 }
 .forgotPassword .content .form .inputBox input[type="submit"]
 {
   padding: 10px;
   background: #0f0;
   color: #000;
   font-weight: 600;
   font-size: 1.35em;
   letter-spacing: 0.05em;
   cursor: pointer;
   background-image: linear-gradient(#f5b342, #f5dd42);
   background: #2C7EAD;
   color: white;
   border-bottom: none;
 
 
 }
 input[type="submit"]:active
 {
   opacity: 0.6;
 }
 @media (max-width: 900px)
 {
   section span 
   {
     width: calc(14vw - 4px);
     height: calc(14vw - 4px);
   }
 }
 @media (max-width: 600px)
 {
  
   section .forgotPassword {
     width: 300px;
   }
 
 }